<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :src="barImage"
    mobile-break-point="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini">P</span>
          <span class="logo-normal">PHARMACY</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <base-item class="item-sidebar" :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <!-- -->
        <template v-if="showInSidebar(item.roles)">
          <base-item-group
            class="item-sidebar"
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item
            v-else
            :key="`item-${i}`"
            :item="item"
            :class="item.to ? '' : 'header-item'"
          />
        </template>
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
const roleUsers = [
  "user_read",
  "user_create",
  "user_update",
  "user_delete",
  "department_read",
  "department_create",
  "department_update",
  "department_delete",
  "product_link_customer_user_read",
  "product_link_customer_user_create",
  "product_link_customer_user_update",
  "product_link_customer_user_delete",
  "customer_read",
  "customer_create",
  "customer_update",
  "customer_delete",
];

const roleOrders = [
  "order_read",
  "order_update",
  "order_delete",
  "order_history_read",
  "order_history_update",
  "order_history_delete",
];

const roleProducts = [
  "product_read",
  "product_create",
  "product_update",
  "product_delete",
  "product_folder_read",
  "product_folder_create",
  "product_folder_update",
  "product_folder_delete",
  "product_utility_read",
  "product_utility_create",
  "product_utility_update",
  "product_utility_delete",
  "product_treatment_read",
  "product_treatment_create",
  "product_treatment_update",
  "product_treatment_delete",
  "product_pathology_read",
  "product_pathology_create",
  "product_pathology_update",
  "product_pathology_delete",
  "product_manufacture_read",
  "product_manufacture_create",
  "product_manufacture_update",
  "product_manufacture_delete",
  "product_unit_read",
  "product_unit_create",
  "product_unit_update",
  "product_unit_delete",
];

const roleReports = [
  "account_report_read",
  "product_report_read",
  "sales_report_read",
  // "result-report_read",
  // "employee-report_read",
  // "status-report_read",
  // "contact-source-report_read",
  // "best-employee_read",
  // "sale-leader_read",
  // "product-group-revenue-report_read",
  // "customer-revenue-report_read",
  // "personal-report_read",
  // "contact-register_read",
  // "employee-report-a-department_read",
];

const roleInformations = [
  "question_answer_read",
  "question_answer_create",
  "question_answer_update",
  "question_answer_delete",
  "shipping_method_read",
  "shipping_method_create",
  "shipping_method_update",
  "shipping_method_delete",
  "payment_read",
  "payment_create",
  "payment_update",
  "payment_delete",
  "regular_read",
  "regular_create",
  "regular_update",
  "regular_delete",
  "privacy_policy_read",
  "privacy_policy_create",
  "privacy_policy_update",
  "privacy_policy_delete",
  "about_us_read",
  "about_us_create",
  "about_us_update",
  "about_us_delete",
];

const roleWebsite = [
  "partner_read",
  "partner_create",
  "partner_update",
  "partner_delete",
  "evaluation_read",
  "evaluation_create",
  "evaluation_update",
  "evaluation_delete",
  "filter_price_read",
  "filter_price_create",
  "filter_price_update",
  "filter_price_delete",
  "filter_percent_discount_read",
  "filter_percent_discount_create",
  "filter_percent_discount_update",
  "filter_percent_discount_delete",
];

const roleDataSetting = [
  "role_read",
  "role_create",
  "role_update",
  "role_delete",
  "notification_read",
  "notification_create",
  "notification_update",
  "notification_delete",
  "notification_customer_read",
  "notification_customer_create",
  "notification_customer_update",
  "notification_customer_delete",
];

const roleCallCenter = [
  "call_center_read",
  "call_center_delete",
  "call_center_view_all",
  "general-call-center-report_read",
  "employee-call-center-report_read",
];

const roleMktSms = ["mkt-sms-message_read", "mkt-sms-customer_read"];

// Utilities
import { mapState } from "vuex";
import TokenService from "../../helpers/token";
import { checkMobile } from "../../helpers/utils";

export default {
  name: "DashboardCoreDrawer",
  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/dashboard",
        roles: ["dashboard_read"],
      },
      {
        to: "",
        title: "Tài khoản",
        roles: roleUsers,
      },
      {
        group: "/users",
        icon: "mdi-account",
        title: "Quản lý tài khoản",
        roles: roleUsers,
        children: [
          {
            title: "Nhóm khách hàng",
            to: "department-list",
            roles: [
              "department_read",
              "department_create",
              "department_update",
              "department_delete",
            ],
          },
          {
            title: "Khách hàng",
            to: "customer-list",
            roles: [
              "customer_read",
              "customer_create",
              "customer_update",
              "customer_delete",
            ],
          },
          {
            title: "Nội bộ",
            to: "user-list",
            roles: ["user_read", "user_create", "user_update", "user_delete"],
          },
          {
            title: "Quản lý kinh doanh",
            to: "manager-product-to-customer-list",
            roles: [
              "product_link_customer_user_read",
              "product_link_customer_user_create",
              "product_link_customer_user_update",
              "product_link_customer_user_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Báo cáo",
        roles: roleReports,
      },
      {
        group: "/reports",
        icon: "mdi-chart-line",
        title: "Báo cáo thống kê",
        roles: roleReports,
        children: [
          {
            title: "Báo cáo tài khoản",
            to: "account-report",
            roles: ["account_report_read"],
          },
          {
            title: "Báo cáo sản phẩm",
            to: "product-report",
            roles: ["product_report_read"],
          },
          {
            title: "Sổ chi tiết bán hàng",
            to: "sales-report",
            roles: ["sales_report_read"],
          },
        ],
      },
      {
        to: "",
        title: "Đơn hàng",
        roles: roleOrders,
      },
      {
        group: "/orders",
        icon: "mdi-order-bool-ascending-variant",
        title: "Quản lý đơn hàng",
        roles: ["order_read", "order_update", "order_delete"],
        children: [
          {
            title: "Danh sách đơn hàng",
            to: "order-list",
            roles: [
              "order_read",
              // "order_create",
              "order_update",
              "order_delete",
            ],
          },
          {
            title: "Lịch sử duyệt đơn",
            to: "order-history",
            roles: [
              "order_history_read",
              // "order_history_create",
              "order_history_update",
              "order_history_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Sản phẩm",
        roles: roleProducts,
      },
      {
        group: "/products",
        icon: "mdi-clipboard-outline",
        title: "Quản lý sản phẩm",
        roles: roleProducts,
        children: [
          {
            title: "Loại sản phẩm",
            to: "product-folder",
            roles: [
              "product_folder_read",
              "product_folder_create",
              "product_folder_update",
              "product_folder_delete",
            ],
          },
          {
            title: "Sản phẩm",
            to: "product-list",
            roles: [
              "product_read",
              "product_create",
              "product_update",
              "product_delete",
            ],
          },
          {
            title: "Nhóm công dụng",
            to: "product-utility",
            roles: [
              "product_utility_read",
              "product_utility_create",
              "product_utility_update",
              "product_utility_delete",
            ],
          },
          {
            title: "Nhóm điều trị",
            to: "product-treatment",
            roles: [
              "product_treatment_read",
              "product_treatment_create",
              "product_treatment_update",
              "product_treatment_delete",
            ],
          },
          {
            title: "Nhóm dược lý",
            to: "product-pathology",
            roles: [
              "product_pathology_read",
              "product_pathology_create",
              "product_pathology_update",
              "product_pathology_delete",
            ],
          },
          {
            title: "Nhà sản xuất",
            to: "product-manufacture",
            roles: [
              "product_manufacture_read",
              "product_manufacture_create",
              "product_manufacture_update",
              "product_manufacture_delete",
            ],
          },
          {
            title: "Đơn vị tính",
            to: "product-unit",
            roles: [
              "product_unit_read",
              "product_unit_create",
              "product_unit_update",
              "product_unit_delete",
            ],
          },
        ],
      },
      {
        icon: "mdi-brightness-7",
        title: "Cài đặt chiết khấu",
        to: "/discounts",
        roles: [
          "discount_read",
          "discount_create",
          "discount_update",
          "discount_delete",
        ],
      },
      {
        icon: "mdi-sale",
        title: "Chương trình khuyến mại",
        to: "/promotions",
        roles: [
          "promotion_read",
          "promotion_create",
          "promotion_update",
          "promotion_delete",
        ],
      },
      {
        to: "",
        title: "Quảng cáo",
        roles: [
          "banner_read",
          "banner_create",
          "banner_update",
          "banner_delete",
        ],
      },
      {
        group: "/banners",
        icon: "mdi-arrow-decision-outline",
        title: "Quản lý banner",
        roles: [
          "banner_read",
          "banner_create",
          "banner_update",
          "banner_delete",
        ],
        children: [
          {
            title: "Thiết lập danh sách",
            to: "banner-list",
            roles: [
              "banner_read",
              "banner_create",
              "banner_update",
              "banner_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Lịch sử",
        roles: ["behavior_customer_read", "behavior_user_read"],
      },
      {
        group: "/actions",
        icon: "mdi-clipboard-text-clock-outline",
        title: "Lịch sử hoạt động",
        roles: ["behavior_customer_read", "behavior_user_read"],
        children: [
          {
            title: "Khách hàng",
            to: "customer",
            roles: ["behavior_customer_read"],
          },
          {
            title: "Nội bộ",
            to: "user",
            roles: ["behavior_user_read"],
          },
        ],
      },
      {
        to: "",
        title: "Thẻ",
        roles: ["tag_read", "tag_create", "tag_update", "tag_delete"],
      },
      {
        group: "/tags",
        icon: "mdi-file-document-edit-outline",
        title: "Quản lý thẻ",
        roles: ["tag_read", "tag_create", "tag_update", "tag_delete"],
        children: [
          {
            title: "Danh sách thẻ",
            to: "tag-list",
            roles: ["tag_read", "tag_create", "tag_update", "tag_delete"],
          },
        ],
      },
      {
        to: "",
        title: "Chính sách",
        roles: roleInformations,
      },
      {
        group: "/information",
        icon: "mdi-information-outline",
        title: "Thông tin chính sách",
        roles: roleInformations,
        children: [
          {
            title: "Câu hỏi thường gặp",
            to: "question-answer",
            roles: [
              "question_answer_read",
              "question_answer_create",
              "question_answer_update",
              "question_answer_delete",
            ],
          },
          {
            title: "Chính sách giao hàng",
            to: "shipping-method",
            roles: [
              "shipping_method_read",
              "shipping_method_create",
              "shipping_method_update",
              "shipping_method_delete",
            ],
          },
          {
            title: "Phương thức thanh toán",
            to: "payment",
            roles: [
              "payment_read",
              "payment_create",
              "payment_update",
              "payment_delete",
            ],
          },
          {
            title: "Chính sách đổi trả",
            to: "regular",
            roles: [
              "regular_read",
              "regular_create",
              "regular_update",
              "regular_delete",
            ],
          },
          {
            title: "Chính sách bảo mật",
            to: "privacy-policy",
            roles: [
              "privacy_policy_read",
              "privacy_policy_create",
              "privacy_policy_update",
              "privacy_policy_delete",
            ],
          },
          {
            title: "Giới thiệu công ty",
            to: "about-us",
            roles: [
              "about_us_read",
              "about_us_create",
              "about_us_update",
              "about_us_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Website",
        roles: roleWebsite,
      },
      {
        group: "/setting-website",
        icon: "mdi-cog-play-outline",
        title: "Dữ liệu website",
        roles: roleWebsite,
        children: [
          {
            title: "Đối tác",
            to: "partner",
            roles: [
              "partner_read",
              "partner_create",
              "partner_update",
              "partner_delete",
            ],
          },
          {
            title: "Cảm nhận khách hàng",
            to: "evaluation",
            roles: [
              "evaluation_read",
              "evaluation_create",
              "evaluation_update",
              "evaluation_delete",
            ],
          },
          {
            title: "Lọc theo giá",
            to: "price-filter",
            roles: [
              "filter_price_read",
              "filter_price_create",
              "filter_price_update",
              "filter_price_delete",
            ],
          },
          {
            title: "Lọc theo chiết khấu",
            to: "percent-discount-filter",
            roles: [
              "filter_percent_discount_read",
              "filter_percent_discount_create",
              "filter_percent_discount_update",
              "filter_percent_discount_delete",
            ],
          },
        ],
      },
      {
        to: "",
        title: "Hỗ trợ",
        roles: [
          "contact_work_read",
          "contact_work_create",
          "contact_work_update",
          "contact_work_delete",
        ],
      },
      {
        icon: "mdi-human-greeting-proximity",
        title: "Khách hàng liên hệ",
        to: "/contact-us",
        roles: [
          "contact_work_read",
          "contact_work_create",
          "contact_work_update",
          "contact_work_delete",
        ],
      },
      {
        to: "",
        title: "Tài liệu",
        roles: [],
      },
      {
        icon: "mdi-newspaper-variant-multiple-outline",
        title: "Tài liệu hướng dẫn",
        to: "/training",
        roles: ["training-manager_read", "training-sale_read"],
      },
      {
        to: "",
        title: "Cài đặt",
        roles: roleDataSetting,
      },
      {
        group: "/data-setting",
        icon: "mdi-database-cog",
        title: "Cài đặt dữ liệu",
        roles: roleDataSetting,
        children: [
          {
            title: "Phân quyền",
            to: "role",
            roles: ["role_read", "role_create", "role_update", "role_delete"],
          },
          {
            title: "Icon thông báo",
            to: "icon",
            roles: ["icon_read", "icon_create", "icon_update", "icon_delete"],
          },
          {
            title: "Thông báo nội bộ",
            to: "notification",
            roles: [
              "notification_read",
              "notification_create",
              "notification_update",
              "notification_delete",
            ],
          },
          {
            title: "Thông báo tới khách hàng",
            to: "notification-customer",
            roles: [
              "notification_customer_read",
              "notification_customer_create",
              "notification_customer_update",
              "notification_customer_delete",
            ],
          },
          // {
          //   title: "Bảng tin thông báo",
          //   to: "announcement",
          //   roles: [
          //     "announcement_read",
          //     "announcement_create",
          //     "announcement_update",
          //     "announcement_delete",
          //   ],
          // },
        ],
      },
    ],
    expandOnHover: false,
  }),

  created() {
    // this.expandOnHover = !checkMobile();
    // console.log(this.expandOnHover);
    Bus.$on("change-drawer", () => {
      this.expandOnHover = !this.expandOnHover;
    });
  },

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        avatarPath: TokenService.getAvatarPath(),
        group: "",
        title: TokenService.getFullName(),
      };
    },
  },

  // watch: {
  //   "$vuetify.breakpoint.smAndDown"(val) {
  //     this.$emit("update:expandOnHover", !val);
  //   },
  // },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
  },
};
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      pointer-events: none
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

      .v-navigation-drawer__content
        overflow-y: hidden

    &.v-navigation-drawer--is-mouseover
      .v-navigation-drawer__content
        overflow-y: auto

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list-item.header-item
      .v-list-item__title
        font-weight: bold !important
        text-transform: uppercase
    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>